import "./App.css";
import { Excalidraw, MainMenu,Footer,Sidebar } from "@excalidraw/excalidraw";
import { useState } from "react";


function App() {
    const [docked, setDocked] = useState(false);
  return (
    <div style={{ height: "100vh" }} className="custom-styles">
      <Excalidraw>
        <MainMenu>
          {/* <MainMenu.DefaultItems.Socials /> */}
          <MainMenu.DefaultItems.LoadScene />
          <MainMenu.Separator />
          <MainMenu.DefaultItems.Export />
          <MainMenu.DefaultItems.SaveAsImage />
          <MainMenu.Separator />
          <MainMenu.DefaultItems.ClearCanvas />
          <MainMenu.Separator />
          <MainMenu.DefaultItems.ChangeCanvasBackground />
          <MainMenu.DefaultItems.ToggleTheme />
          <MainMenu.Separator />
          <MainMenu.Item
            onSelect={() =>
              window.alert("Maintained By REPADTECH PRIVATE LIMITED")
            }
          >
            About US
          </MainMenu.Item>
        </MainMenu>

        <Sidebar name="custom" docked={docked} onDock={setDocked}>
          <Sidebar.Header />
          <Sidebar.Tabs style={{ padding: "0.5rem" }}>
            <Sidebar.Tab tab="one">Tab one!</Sidebar.Tab>
            <Sidebar.Tab tab="two">Tab two!</Sidebar.Tab>
            <Sidebar.TabTriggers>
              <Sidebar.TabTrigger tab="one">One</Sidebar.TabTrigger>
              <Sidebar.TabTrigger tab="two">Two</Sidebar.TabTrigger>
            </Sidebar.TabTriggers>
          </Sidebar.Tabs>
        </Sidebar>

        <Footer>
          <Sidebar.Trigger
            name="custom"
            tab="one"
            style={{
              marginLeft: "0.5rem",
              background: "#70b1ec",
              color: "white",
            }}
          >
            AI Tab
          </Sidebar.Trigger>
        </Footer>
      </Excalidraw>
    </div>
  );
}

export default App;
